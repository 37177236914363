.heroSection {
  position: relative;
  height: 800px;
  background-image: url("./assets/Picture4.webp");
  background-size: cover;
  background-position: center center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: white;
}

.h1Custom {
  font-size: 100px;
}

.heroSectionHeading {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 100px;
}

.heroSectionHeading h1 {
  color: white;
  font-size: 72px;
  font-weight: 400;
  position: relative;
  z-index: 5;
}

.navbarContainer {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 36px;
  box-sizing: border-box;
}

.navbarContainer .linkButton {
  background-color: #ffca42 !important;
  color: #1b3764 !important;
}

.navItem {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navItem a {
  text-decoration: none;
  color: white;
  width: max-content;
}

.logo {
  width: 150px;
  height: auto;
}

.introSection {
  display: flex;
  gap: 24px;
  padding: 48px;
  background-color: #f4f8ff;
}

.introSection h4 {
  color: #1b3764;
  font-size: 60px;
}

.introSection p {
  text-align: justify !important;
}

.textSection {
  margin: auto;
}

.bookSectionWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 80px 48px;
  gap: 36px;
  background-color: #f4f8ff;
}

.bookSectionWrapper h4 {
  color: #1b3764;
  text-align: center;
  font-size: 60px;
}

.bookSectionWrapper .bookSection {
  display: flex;
  gap: 16px;
  max-width: 500px;
  width: 100%;
  height: 200px;
}

.bookSectionWrapper .booksDetail {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
}

.bookSectionWrapper .booksDetail h6 {
  color: #1b3764;
}

.bookSectionWrapper .booksDetail p {
  color: #969aa0;
}

.booksDetail a {
  /* background-color: #ffca42 !important; */
  color: #1b3764 !important;
  border: 1px solid #1b3764;
}

.booksDetail a:hover {
  background-color: #ffca42 !important;
  color: #1b3764 !important;
  border: 1px solid #ffca42;
}

.footer {
  height: 100px;
  background-color: #1b3764;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer p {
  color: white;
  font-size: 16px;
}

.bannerOrder .linkButton:hover {
  background-color: #ffca42 !important;
  color: #1b3764 !important;
  border: 1px solid #ffca42;
}

.galleryContainer h4 {
  color: #1b3764;
  text-align: center;
  font-size: 60px;
}

.bookColumnContainer {
  justify-content: space-around;
  display: flex;
}
.imageWidth {
  width: 125px !important;
}

/* Media query for mobile screens */
@media (max-width: 600px) {
  .heroSection {
    height: 240px !important;
  }
  .heroSectionHeading h1 {
    font-size: 36px !important;
  }

  .heroSectionHeading {
    padding: 0 20px !important;
  }

  .navItem {
    gap: 10px !important;
  }

  .navbarContainer {
    padding: 8px 20px !important;
  }

  .galleryContainer h4 {
    font-size: 36px !important;
  }

  .introSection h4 {
    font-size: 26px !important;
    text-align: center !important;
    margin-bottom: 16px;
  }

  .introSection p {
    font-size: 16px !important;
  }

  .authorPic {
    text-align: center !important;
  }

  .bookSectionWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    gap: 26px;
  }

  .bookSectionWrapper h4 {
    font-size: 36px !important;
  }
  .bookSectionWrapper .bookSection {
    flex-direction: column;
    gap: 16px;
    height: 100%;
  }

  .bookSectionWrapper img {
    margin: auto;
    width: 200px;
  }
  .imageWidth {
    width: 200px !important;
  }
}

@media (max-width: 1100px) {
  .bookColumnContainer {
    flex-direction: column;
    gap: 16px;
  }

  .bookColumnContainer > div {
    margin: auto;
  }
}
@media (max-width: 900px) {
  .introSection {
    flex-direction: column !important;
    padding: 20px !important;
  }
  .introSection img {
    width: 300px !important;
  }
}
